// Interface describing the details of a ticket
export class TicketDetail {
  ticketId: number; // Ticket ID
  updateScheduleAt: string; //updateScheduleAt
  ticketType: string; // Type of the ticket (e.g., "General Admission")
  ticketName: any = {}; // Full name of the ticket in different languages
  ticketNameShort: any = {}; // Shortened name of the ticket in different languages
  companyId: number;
  companyName: any = {};
  operatorId: number;
  operatorName: any = {};
  appId: string;
  appName: any;
  subtitle: any = {}; // Subtitle of the ticket in different languages
  note: any = {}; // Notes associated with the ticket
  activateMessage: any = {}; // Activation messages associated with the ticket
  isInUseAdult: boolean;
  isInUseCustom1: boolean;
  isInUseCustom2: boolean;
  isInUseCustom3: boolean;
  priceLabelCustom1: string;
  priceLabelCustom2: string;
  priceLabelCustom3: string;
  referencePriceAdult: number;
  referencePriceCustom1: number;
  referencePriceCustom2: number;
  referencePriceCustom3: number;
  currencyUnit: string;
  couponCount: number; // Count of coupons associated with the ticket
  couponGrantingType: string; // Type of coupon granting for the ticket
  inUseOperation: number; // Operation details indicating current use
  usingPeriod: number; // Period during which the ticket can be used
  usingPeriodType: string; // Type of period during which the ticket can be used
  enableActivateWeekdays: string[] = []; // Days of the week for ticket activation
  availableActiveStartTime: string;
  availableActiveEndTime: string;
  availableShowEndTime: string;
  enableOn: string[] = []; // Specific dates when the ticket is enabled
  disableOn: string[] = []; // Specific dates when the ticket is disabled
  colorCircleUpper: string; // Color code for the upper circle
  colorCircleLower: string; // Color code for the lower circle
  colorCircleRotation: string; // Color code for circle rotation animation
  image1: string; // URL or path for the first image
  image2: string; // URL or path for the second image
  colorFontTicket1Top: string; // Color code for font in ticket option 1 (top)
  colorFontTicket1Center: string; // Color code for font in ticket option 1 (center)
  colorFontTicket1Bottom: string; // Color code for font in ticket option 1 (bottom)
  colorFontTicket2Top: string; // Color code for font in ticket option 2 (top)
  colorFontTicket2Center: string; // Color code for font in ticket option 2 (center)
  colorFontTicket2Bottom: string; // Color code for font in ticket option 2 (bottom)
  isReserve: boolean;
  reservationAvailablePeriod: number;
  reservationAvailablePeriodUnit: string;
  reservationDeadline: number;
  reservationDeadlineUnit: string;
  reservationDisplayStart: number;
  reservationDisplayStartUnit: string;
  isDetailCustomName1: boolean; // Flag indicating if custom name 1 details are available
  isDetailCustomName2: boolean; // Flag indicating if custom name 2 details are available
  isDetailCustomName3: boolean; // Flag indicating if custom name 3 details are available
  image1Path: string;
  image2Path: string;
  urlImage1: string; // url image 1
  urlImage2: string; // url image 2
  adultCount: number; //count of adult
  customCount1: number; //count of custom1
  customCount2: number; //count of custom2
  customCount3: number; //count of custom3
  totalCount: number;

  //new param
  occupancyFewPercentage: any;
  isDisplayRemainingStock: boolean;
  sectionType: boolean;
  reservationDeadlineTime: any;

  qrType: string;
  qrGateIds: [];
  reservationChangeType: string;

  constructor() {}
}

// Interface representing language structure
export class Language {
  ja: string; // Japanese language representation
  en: string; // English language representation
}
